import React, { useState, useEffect } from 'react';

import './MainPic.css'

const MainPic = () => {

    const [state, setState] = useState({ navbarHeight: 0 }); // Height of navbar

    useEffect(() => {
        const navbarHeight = document.getElementById('navbar').clientHeight; // Get the height of the navbar
        setState({ navbarHeight });
    }, []);

    return (
        <div id="home" className="wrapper">
            <section style={{height: `calc(100vh - ${state.navbarHeight}px)`}}> 
                <p className="title">רואי חשבון שמבינים עסקים</p>
                <p className="text">ראיית חשבון מזווית אחרת...</p>
            </section>
        </div>
    );
}

export default MainPic;