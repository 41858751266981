import React, { Component } from 'react';

import { Navbar, Nav } from 'react-bootstrap';
import { RiPhoneLine } from 'react-icons/ri';

import './AppNav.css'

class AppNav extends Component {
    state = {
        isTop: true,
        didScroll: false,
    };

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 50;
            if (isTop !== this.state.isTop) {
                this.setState({
                    isTop,
                    didScroll: true
                })
            }

            if (this.state.didScroll) { // Change animation except first load (after refresh)
                var elem = document.getElementById('navbar');
                if (elem !== null) {
                    elem.style.animation = (isTop ? "startLight" : "startDark") + " 0.5s forwards"
                }
            }
        });
    }

    handleLinkClick = (event) => {
        event.preventDefault();
        const Targetid = event.target.href.split('#')[1];
        const anchor = document.querySelector(`#${Targetid}`)
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    render() {
        return (
            <Navbar id="navbar" collapseOnSelect expand="lg" variant="" sticky="top" className={this.state.isTop ? 'navbar-light' : 'navbar-dark'}>
                <Navbar.Brand href="#home">גרנות רואי חשבון</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav activeKey="" className="ml-auto">
                        <Nav.Link href="#home" onClick={this.handleLinkClick}>בית</Nav.Link>
                        <Nav.Link href="#about" onClick={this.handleLinkClick}>קצת עלינו</Nav.Link>
                        <Nav.Link href="#services" onClick={this.handleLinkClick}>שירותים</Nav.Link>
                        <Nav.Link href="#contact" onClick={this.handleLinkClick}>צור קשר</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="d-lg-block d-none" ><RiPhoneLine id="phoneIcon" />050-766-1633</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default AppNav;