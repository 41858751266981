import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';

import AOS from 'aos';
import 'aos/dist/aos.css';

import "./About.css"

class About extends Component {

    componentDidMount() {
        AOS.init({ duration: 3000 });
    }

    render() {
        return (
            <Container>
                <div className="section row" id="about">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <h3 className="title">אודות משרדינו</h3>
                        <div className="aboutInfo">משרד רו"ח גרנות נוסד בשנת 1980.
                            <br />מאז הקמתו ועד היום דוגל המשרד במתן שירות אישי, מקצועי, יסודי ומבטיח פתרון מהיר לכל אתגר.
                            </div>
                        <Row className="pictures justify-content-md-center">
                            <div className="col-md-4">
                                <img src="/images/Rami-small.png" alt="rami"></img>
                                <p>רו"ח <b>רמיאל גרנות</b>, בעל תואר ראשון בכלכלה מאוניברסיטה העברית בירושלים ותואר שני במנהל עסקים.</p>
                            </div>
                            <div className="col-md-4">
                                <img src="/images/Yoav-small.png" alt="yoav"></img>
                                <p>רו"ח <b>יואב גרנות</b>, בעל תואר ראשון בחשבונאות ומנהל עסקים מהמסלול האקדמי המכללה למנהל ותואר שני במנהל עסקים ומיסים ממסלול אקדמי קרית אונו.</p>
                            </div>
                        </Row>
                    </div>
                    <div className="col-md-4 d-lg-block d-none aos-anim">
                        <img data-aos="fade-right" src="/svgs/calculator.svg" alt="CPA Calculator"></img>
                    </div>
                </div>
            </Container>
        );
    }
}

export default About;