import React, { useState } from 'react';
import { Container, Form, Col, InputGroup, Button } from 'react-bootstrap';
import { IoIosAt, IoIosCall, IoIosPerson, IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";


import './Contact.css'

const Contact = () => {

    const [state, setState] = useState({ name: '', mail: '', phone: '' });
    const [validated, setValidated] = useState(false);
    const [loader, setLoader] = useState(false);
    const [statusMessage, setStatusMessage] = useState({ message: 'אורי הוא מלך', success: true, visible: false });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };

    let loaderVisibilityState = loader ? "visible" : "hidden";


    const submitForm = (event) => {
        event.preventDefault();
        setValidated(true);

        setStatusMessage({ // Reset status message
            message: "אורי הוא מלך",
            color: "green",
            visible: false,
        })

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        setLoader(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(state)
        };
        fetch('https://granotcpa.co.il/server/submitContactForm', requestOptions)
            .then(response => response.json())
            .then((data) => {
                let { message, success } = data;

                setStatusMessage({
                    message,
                    success,
                    visible: true,
                })

                // Reset states
                setState({ name: '', mail: '', phone: '' });
                setValidated(false);
                setLoader(false);
            }).catch((err) => {
                setStatusMessage({
                    message: "אירעה שגיאה בעת ניסיון שליחת הפרטים, אנא נסו שנית או התקשרו אלינו..",
                    success: false,
                    visible: true,
                })

                // Reset states
                setState({ name: '', mail: '', phone: '' });
                setValidated(false);
                setLoader(false);
            });
    }

    return (
        <>
            <Container>
                <div className="section" id="contact">
                    <h3 className="title">לייעוץ ופרטים נוספים</h3>
                    <p className="text">מלאו פרטים ונחזור אליכם בהקדם או התקשרו:<a className="callHref" href="tel:050-766-1633"> 050-766-1633</a></p>
                    <Form noValidate validated={validated} onSubmit={submitForm}>
                        <Form.Row className="justify-content-md-center">
                            <Form.Group as={Col} sm={3} className="my-1" controlId="inputName">
                                <Form.Label srOnly>שם</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>< IoIosPerson /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        placeholder="שם"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                        required
                                        minLength={2}
                                        maxLength={25}
                                        type="text"
                                    />
                                    <Form.Control.Feedback type="invalid">אנא הכנס שם מלא</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm={3} className="my-1" controlId="inputMail">
                                <Form.Label srOnly>דוא"ל</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>< IoIosAt /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        placeholder='דוא"ל'
                                        name="mail"
                                        value={state.mail}
                                        onChange={handleChange}
                                        maxLength={40}
                                        required
                                        type="email" />
                                    <Form.Control.Feedback type="invalid">אנא הכנס כתובת דוא"ל תקינה</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm={3} className="my-1" controlId="inputPhone">
                                <Form.Label srOnly>טלפון</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>< IoIosCall /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        placeholder='טלפון'
                                        name="phone"
                                        value={state.phone}
                                        onChange={handleChange}
                                        required
                                        minLength={8}
                                        maxLength={10}
                                        type="tel" />
                                    <Form.Control.Feedback type="invalid">אנא הכנס מספר טלפון</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Col xs="auto" className="my-1">
                                <Button type="submit">שליחה</Button>
                            </Col>
                        </Form.Row>
                    </Form>
                    <div id="statusMessage" style={{ display: statusMessage.visible ? "block" : "none", color: (statusMessage.success === true) ? "green" : "red" }}>
                        <div className="icon">
                            {statusMessage.success === true ? <IoIosCheckmarkCircleOutline size={30} /> : <IoIosCloseCircleOutline size={30} />}
                            <div className="message">{statusMessage.message}</div>
                        </div>
                    </div>
                    <div className="lds-roller" style={{ visibility: loaderVisibilityState, display: (statusMessage.visible === true) ? "none" : "inline-block" }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
                <path transform="rotate(-11.035168647766113 1162.772705078125,187.54618835449148)" fill="#7fc7f6" d="m1530.910523,169.670185l-40.904207,-38.653612c-40.904207,-39.379502 -122.71262,-115.597892 -204.521033,-96.906239c-81.808413,19.417542 -163.616827,135.559852 -245.42524,184.012971c-81.808413,47.72723 -163.616827,29.579994 -204.521033,19.23607l-40.904207,-9.618035l0,116.142309l40.904207,0l204.521033,0l245.42524,0l204.521033,0l40.904207,0l0,-174.213464z" />
            </svg>
        </>
    );
}

export default Contact;