import React, { Component } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

import "./Services.css"

class Services extends Component {

    componentDidMount() {
        AOS.init({ duration: 2000 });
    }

    render() {
        return (
            <>
                <div className="wave">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130">
                        <path fill="#7fc7f6" d="m0,79l80,-21.3c80,-21.7 240,-63.7 400,-53.4c160,10.7 320,74.7 480,101.4c160,26.3 320,16.3 400,10.6l80,-5.3l0,64l-80,0l-400,0l-480,0l-400,0l-80,0l0,-96z" />
                    </svg>
                </div>
                <div className="section" id="services">
                    <Container>
                        <div className="row">
                            <div className="col-md-4 d-lg-block d-none animateImage">
                                <img data-aos="fade-left" src="/svgs/collabration.svg" alt="Collabration - Our Services"></img>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-8 servicesText">
                                <h3 className="title">השירותים שלנו</h3>
                                <div className="text">
                                    <span className="preText">המשרד מעניק מטריית שירותים מקיפה המותאמת לצורכי הלקוח ולמידותיו, בין היתר:</span>
                                    <ListGroup className="servicesList" variant="flush">
                                        <ListGroup.Item><b>סיוע מקיף בפתיחת עסקים וחברות</b> - <span className="listInfo">פתיחת תיקים ברשויות המס, יעוץ מקיף ללקוח על אופן ההתנהלות הפיננסית והעסקית.</span></ListGroup.Item>
                                        <ListGroup.Item><b>חשבונאות וביקורת</b> - <span className="listInfo">ביקורת וסקירת דוחות כספיים לרבות עריכת דוחות כספיים, בדיקת נאותות חשבונאיות, ייעוץ בנושאים חשבונאיים ואישורים מיוחדים.</span></ListGroup.Item>
                                        <ListGroup.Item><b>מיסוי תאגידים</b> - <span className="listInfo">ייעוץ שוטף בנושאי מס, תכנוני מס, מחירי העברה, מיסוי בינלאומי, מתן חוות דעת מקצועיות וייצוג שוטף מול רשויות המס.</span></ListGroup.Item>
                                        <ListGroup.Item><b>מיסוי פרט</b> - <span className="listInfo">טיפול בהחזרי מס לשכירים, הכנה והגשת דוחות אישים, הצהרות הון תוך מיצוי כלל הזכויות המגיעות להפחתת מס.</span></ListGroup.Item>
                                        <ListGroup.Item><b>הנהלת חשבונות והנפקת תלושי שכר</b> - <span className="listInfo">המשרד מעניק את כל שירותי הנהלת החשבונות לחברות ועצמאים בכל ענפי המשק. לרשות המשרד צוות גדול ובעל ניסיון רב של מנהלי חשבונות.</span></ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="wave">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 141">
                        <path fill="#7fc7f6" d="m-1,137l80.333338,-32c80.333338,-32 241.000013,-96 401.666689,-101.3c160.666676,-5.7 321.333351,48.3 482.000027,64c160.666676,16.3 321.333351,-5.7 401.666689,-16l80.333338,-10.7l0,-128l-80.333338,0l-401.666689,0l-482.000027,0l-401.666689,0l-80.333338,0l0,224z" />
                    </svg>
                </div>
            </>
        );
    }
}

export default Services;