import React, { Component } from 'react';

import AppNav from '../AppNav/AppNav'
import MainPic from '../MainPic/MainPic'
import Layout from '../Layout/Layout'
import About from '../About/About'
import Services from '../Services/Services'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'

import './App.css'

class App extends Component {
    render() {
        return (
            <>
                <AppNav />
                <MainPic />
                <Layout>
                    <About />
                    <Services />
                    <Contact />
                </Layout>
                <Footer />
            </>
        );
    }
}

export default App;