import React, { Component } from 'react';

import { Navbar, Nav } from 'react-bootstrap';

import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <Navbar className="footer" sticky="bottom" variant="">
                <Nav className="ml-auto">
                    <Nav.Item>האתר פותח ע"י <a className="ref" href="https://github.com/origranot">אורי גרנות</a></Nav.Item>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Item>Granot &copy; {new Date().getFullYear()} - All Rights Reserved | Illustration by Stories by <a className="ref" href="https://stories.freepik.com/work">Freepik</a></Nav.Item>
                </Nav>
            </Navbar>

        );
    }
}

export default Footer;